import Vue from "vue";
import Vuex from "vuex";
import api from "../api/api";
import router from "../router";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        Sidebar_drawer: null,
        Customizer_drawer: false,
        SidebarColor: "white", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
        SidebarBg: "",
        navbarColor: "#fafafa",
        setHorizontalLayout: false, // Horizontal layout\
        darktheme: false,
        accessToken: null,
        firebaseToken: null,
        results: null,
        study_year: null,
        errorMessageShow: false,
        btnLoginLoading: false,

        FileUrl: null,
    },
    mutations: {
        SET_SIDEBAR_DRAWER(state, payload) {
            state.Sidebar_drawer = payload;
        },
        SET_CUSTOMIZER_DRAWER(state, payload) {
            state.Customizer_drawer = payload;
        },
        SET_SIDEBAR_COLOR(state, payload) {
            state.SidebarColor = payload;
        },
        SET_NAVBAR_COLOR(state, payload) {
            state.navbarColor = payload;
        },
        SET_LAYOUT(state, payload) {
            state.setHorizontalLayout = payload;
        },
        authUser(state, userData) {
            state.accessToken = userData.accessToken
            state.firebaseToken = userData.firebaseToken
            state.results = userData.results
            state.study_year = userData.study_year
            state.FileUrl = userData.content_url
            state.errorMessageShow = false
            state.btnLoginLoading = false
        },
        clearAuthUser(state) {
            state.accessToken = null
            state.results = null
            state.btnLoginLoading = false
            state.study_year = null
        },
        errorLogin(state) {
            state.errorMessageShow = true
            state.btnLoginLoading = false
        },
        changeLoginBtnToTruefunction(state) {
            state.btnLoginLoading = true
        },
        changeStudyYear(state, study_year) {
            state.study_year = study_year
        }
    },
    actions: {
        async submitLogin({ commit }, authData) {
            let response = await api.login(
                authData.email,
                authData.password,
                navigator.appVersion,
                authData.userInfo.query,
                authData.userInfo.city,
                authData.userInfo.lat,
                authData.userInfo.lat,
            )

            if (response.error === false) {

                commit('authUser', {
                    accessToken: response.results.data.results.token,
                    firebaseToken: authData.firebaseToken,
                    results: response.results.data.results,
                    study_year: response.results.data.study_year,
                    content_url: response.results.data.results.content_url,
                })

                localStorage.setItem('accessToken', response.results.data.results.token)
                localStorage.setItem('firebaseToken', authData.firebaseToken)
                localStorage.setItem('results', JSON.stringify(response.results.data.results))
                localStorage.setItem('study_year', JSON.stringify(response.results.data.study_year))

                // studyYear
                // const mainData = await api.mainData()

                // localStorage.setItem("features", JSON.stringify(mainData.data.features))

                // localStorage.setItem("study_year", JSON.stringify(mainData.data.study_year))

                // localStorage.setItem("studyYearData", JSON.stringify(mainData.data.studyYearData))

                router.push('/dashboards/customers').catch(err => {
                    console.log(err)
                })
            } else {
                commit('errorLogin')
            }
        },

        tryLoginAuth({ commit }) {
            const accessToken = localStorage.getItem('accessToken')
            const results = localStorage.getItem('results')
            if (!accessToken) {
                return
            }
            commit('authUser', {
                accessToken,
                results,
            })
        },
        submitLogout({ commit }) {
            commit('clearAuthUser')
            localStorage.clear('accessToken')
            localStorage.clear('results')
            localStorage.setItem('reloaded', 'false')
            router.replace('/')
        },
        setLogoutTimer({ commit }, expireIN) {
            setTimeout(() => {
                commit('clearAuthUser')
                localStorage.clear('accessToken')
                localStorage.clear('results')
                localStorage.setItem('reloaded', 'false')
                router.replace('/')
            }, expireIN)
        },
        changeLoginBtnToTrue({ commit }) {
            commit('changeLoginBtnToTruefunction')
        },
        changeStudyYear({ commit }, study_year) {
            commit('changeStudyYear', study_year)
        },
    },
    getters: {},
});