import Vue from "vue";
import Router from "vue-router";
// import goTo from "vuetify/es5/services/goto";

Vue.use(Router);

const router = new Router({
    // mode: "history",
    // base: process.env.BASE_URL,
    // // This is for the scroll top when click on any router link
    // scrollBehavior: (to, from, savedPosition) => {
    //     let scrollTo = 0;

    //     if (to.hash) {
    //         scrollTo = to.hash;
    //     } else if (savedPosition) {
    //         scrollTo = savedPosition.y;
    //     }

    //     return goTo(scrollTo);
    // },

    // This is for the scroll top when click on any router link
    routes: [{
            path: "/",
            redirect: "authentication/boxedlogin",
            // component: () =>
            //     import ("@/layouts/blank-layout/Blanklayout"),
            // children: [{
            //     name: "BoxedLogin",
            //     path: "boxedlogin",
            //     component: () =>
            //         import ("@/views/authentication/BoxedLogin"),
            // }, ]
        },

        {
            path: "/dashboards",
            component: () =>
                import ("@/layouts/full-layout/Layout"),
            children: [{
                    name: "customers",
                    path: "customers",
                    component: () =>
                        import ("@/views/customers/customers"),
                },
                {
                    name: "Apps",
                    path: "apps/chat",
                    component: () =>
                        import ("@/views/apps/chat/Chat"),
                },
            ]
        },

        {
            path: "/authentication",
            component: () =>
                import ("@/layouts/blank-layout/Blanklayout"),
            children: [{
                name: "BoxedLogin",
                path: "boxedlogin",
                component: () =>
                    import ("@/views/authentication/BoxedLogin"),
            }, {
                name: "customerLink",
                path: "customerLink/:id",
                component: () =>
                    import ("@/views/customers/customerLink"),
            }, ],
        },
        {
            path: "*",
            component: () =>
                import ("@/views/authentication/Error"),
        },


        // {
        //     path: "*",
        //     component: () =>
        //         import ("@/views/Error"),
        // },
    ],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
    // If this isn't an initial page load.
    if (to.name) {
        // Start the route progress bar.
        NProgress.start(800);
    }
    next();
});

router.afterEach(() => {
    // Complete the animation of the route progress bar.
    NProgress.done();
});

export default router;