import axios from 'axios'
import sha512 from 'js-sha512'

class Api {
    async login(account_email, account_password, auth_phone_details, auth_ip, auth_city, auth_lat, auth_lon) {
        return axios
            .post('/login', {
                phone: account_email,
                account_password: sha512(account_password),
                auth_phone_details,
                auth_ip,
                auth_city,
                auth_lat,
                auth_lon,

                // auth_phone_id: authData.firebaseToken,
                // auth_firebase: authData.firebaseToken,
            })
            .then(Response => ({ error: false, results: Response }))
            .catch(() => ({ error: true, results: [] }))
    }

    // mainData
    async mainData() {
        axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

        return axios
            .get(`/mainData`)
            .then(Response => Response)
            .catch(error => {
                console.log('error', error)

                return error.response
            })
    }

    async logout() {
        axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
        return axios
            .get('/logout')
            .then(Response => {
                if (Response.error === false) {
                    return { error: false, results: Response }
                }

                return { error: true, results: Response }
            })
            .catch(() => ({ error: true, results: [] }))
    }


    // customers

    async getCustomers({ page, limit, search }) {
        axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
        return axios
            .post('/customers/get', {
                page,
                limit,
                search
            })
            .then(Response => Response)
            .catch(() => ({ error: true, results: [] }))
    }


    async addCustomers({
        name,
        phone,
        address,
        password,
        password_show,
        market_name,
        idInstance,
        apiTokenInstance
    }) {
        axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
        return axios
            .post('/customers/add', {
                name,
                phone,
                address,
                password,
                password_show,
                market_name,
                idInstance,
                apiTokenInstance,
            })
            .then(Response => Response)
            .catch(error => {
                console.log('error', error)

                return error.response
            })
    }


    async editCustomers({
        _id,
        name,
        phone,
        address,
        password,
        password_show,
        market_name,
        idInstance,
        apiTokenInstance
    }) {
        axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
        return axios
            .put('/customers/edit', {
                _id,
                name,
                phone,
                address,
                password,
                password_show,
                market_name,
                idInstance,
                apiTokenInstance,
            })
            .then(Response => Response)
            .catch(error => {
                console.log('error', error)

                return error.response
            })
    }


    async removeCustomers(id) {
        axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
        return axios
            .delete(`/customers/remove/id/${id}`)
            .then(Response => Response)
            .catch(error => {
                console.log('error', error)

                return error.response
            })
    }



    async getCustomerQr(idInstance, apiTokenInstance) {
        // axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')


        let res = await axios
            .get(`https://api.green-api.com/waInstance${idInstance}/qr/${apiTokenInstance}`)
            .then(Response => Response)
            .catch(error => {
                console.log('error', error)

                return error.response
            })

        return res
    }

}

export default new Api()